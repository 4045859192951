import { add } from 'date-fns';

import { menuConfig, menuConfigObject } from '@/config/menuConfig';
import { available_languages, defaultSiteLanguage } from '@/globalConsts';

import { HTTP_ERRORS } from './errorCodes';

export const formatDate = (dateString: string | null | undefined) => {
	const removeTimestamp = (dateString: string | null) => {
		return dateString?.split('T')[0];
	};
	if (!dateString) return null;
	const dateArr = dateString.includes('T')
		? removeTimestamp(dateString)?.split('-')
		: dateString.split('-');
	if (dateArr && dateArr.length > 2) {
		if (defaultSiteLanguage === 'en-us') {
			// returns MM/DD/YYYY format
			return `${dateArr[1]}.${dateArr[2]}.${dateArr[0]}`;
		} else {
			// returns DD/MM/YYYY format
			return dateArr.reverse().join('.');
		}
	}
	// fallback to the input (just in case!)
	return dateString;
};

export const formatLongDateTime = (dateString?: string | null) => {
	const options = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	} as const;
	if (dateString) {
		return new Date(dateString).toLocaleTimeString('en-UK', options);
	} else {
		return null;
	}
};

export const formatSnakeCase = (textString?: string | null) => {
	if (textString) {
		return textString.replace('_', ' ');
	} else {
		return null;
	}
};

export const formatErrorCodes = (payload: { status: number | string; data: object }) => {
	if (payload.data !== null) {
		// when API returns 404 error page.
		return payload.status === 'PARSING_ERROR' ? HTTP_ERRORS[404] : Object.values(payload.data);
	} else
		return Object.keys(HTTP_ERRORS).includes(payload.status + '')
			? HTTP_ERRORS[payload.status]
			: 'Something went wrong. Please try again later.';
};

export const formatContactMethod = (contactMethod: string | unknown) => {
	switch (contactMethod) {
		case 'follow_up':
			return 'Follow up';
		case 'mobile_phone_call':
			return 'Mobile phone';
		case 'home_phone_call':
			return 'Home phone';
		case 'sms':
			return 'SMS';
		case 'email':
			return 'Email';
	}
};

export const getCurrentTabValue = (defaultTabValue: number, storedTabValue: number | undefined) => {
	return storedTabValue === undefined ? defaultTabValue : storedTabValue;
};

export const setScreenSizeAnalyticsValues = () => {
	const vWidth = window.innerWidth;
	const vHeight = window.innerHeight;
	const viewport = `${vWidth}px x ${vHeight}px`;

	const sWidth = screen.width;
	const sHeight = screen.height;
	const screenResolution = `${sWidth}px x ${sHeight}px`;

	const displayValues = {
		viewport,
		screenResolution,
	};

	return displayValues;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <T extends (...args: any[]) => ReturnType<T>>(
	fn: T,
	timeout = 300
): ((...args: Parameters<T>) => void) => {
	let timer: ReturnType<typeof setTimeout>;
	return (...args: Parameters<T>) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			fn(...args);
		}, timeout);
	};
};

export const formatLanguage = (lang: string) => {
	return available_languages[lang];
};

export const getDates = (startDate: Date, stopDate: Date) => {
	// returns an array of dates between startDate and stopDate
	const dateArray = [];
	let currentDate = startDate;
	while (currentDate <= stopDate) {
		dateArray.push(new Date(currentDate));
		currentDate = add(currentDate, { days: 1 });
	}
	return dateArray;
};

export const renderCurrency = (currency: string | undefined) => {
	switch (currency) {
		case 'GBP':
			return '£';
		case 'USD':
			return '$';
		default:
			return '£';
	}
};

export const getFilteredRoles = (userFeatures: string[] | undefined) => {
	return menuConfig.filter((item: menuConfigObject) => {
		if (item.roles && item.roles.length > 0) {
			return userFeatures?.some((role: string) => item.roles?.includes(role)) || false;
		} else {
			return item;
		}
	});
};
