import { FieldWrapper, FieldWrapperPassThroughProps } from '@@/components/Form';
import clsx from 'clsx';
import { FieldHookConfig, useField } from 'formik';

type Option = {
	label: React.ReactNode;
	value: string | number | string[];
};

type SelectFieldProps = FieldWrapperPassThroughProps & {
	options: Option[];
	className?: string;
	defaultValue?: string;
	placeholder?: string;
	disabled?: boolean;
	dataTestId?: string;
	handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const SelectField = (props: SelectFieldProps & FieldHookConfig<string>) => {
	const {
		label,
		name,
		options,
		className,
		defaultValue,
		placeholder,
		width,
		handleChange,
		disabled,
		dataTestId,
	} = props;
	const [field, meta] = useField(props);
	const { error, touched } = meta;
	return (
		<FieldWrapper
			label={label}
			disabled={disabled}
			error={error}
			touched={touched}
			width={width}
			name={name}
		>
			<select
				placeholder={placeholder}
				id={name}
				disabled={disabled}
				data-test-id={dataTestId}
				className={clsx(
					'mt-1 block w-full pl-3 pr-10 py-2 text-base text-primaryTextDark rounded border-primaryBorder focus:outline-none focus:ring-primaryBrand focus:border-primaryBrand',
					className
				)}
				defaultValue={defaultValue}
				{...field}
				onChange={(e) => {
					handleChange(e);
					field.onChange(e);
				}}
			>
				<option value='' disabled data-test-id={'optionDefault'}>
					Please select...
				</option>
				{options.map(({ label, value }) => (
					<option key={label?.toString()} value={value}>
						{label}
					</option>
				))}
			</select>
		</FieldWrapper>
	);
};
