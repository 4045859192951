import clsx from 'clsx';
import AlertCircleOutline from 'mdi-material-ui/AlertCircleOutline';

const widths = {
	col1: 'col-span-1',
	col2: 'col-span-2',
	col3: 'col-span-3',
	col4: 'col-span-4',
	col5: 'col-span-5',
	col6: 'col-span-6',
};

export type FieldWrapperProps = {
	label?: string | React.ReactElement;
	name?: string;
	className?: string;
	width?: keyof typeof widths;
	children?: React.ReactNode;
	error?: string;
	touched?: boolean;
	disabled?: boolean;
	labelSrOnly?: boolean;
};

export type FieldWrapperPassThroughProps = Omit<FieldWrapperProps, 'className' | 'children'>;

export const FieldWrapper = (props: FieldWrapperProps) => {
	const {
		label,
		name,
		className,
		error,
		touched,
		children,
		disabled = false,
		labelSrOnly = false,
	} = props;

	return (
		<div className={clsx(disabled ? 'opacity-25' : '', className)} data-test-id='form-field'>
			{label && (
				<label
					htmlFor={name}
					className={clsx(
						'block text-primaryTextDark',
						className,
						labelSrOnly ? 'sr-only' : ''
					)}
				>
					{label}
				</label>
			)}
			<div className={clsx('mt-1 relative rounded-md')}>{children}</div>
			{touched && error ? (
				<p className={clsx('mt-2 text-base text-error')} id='form-field-error'>
					<AlertCircleOutline
						className='pr-2 mt-[-3px]'
						data-test-id='AlertCircleOutlineIcon'
					/>
					{error}
				</p>
			) : null}
		</div>
	);
};
