import { Button, Typography } from '@@/components/Elements';
import { InputField, FormContainer as Form, FormActions, FormValues } from '@@/components/Form';
import { FormikHelpers, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useResetPasswordMutation } from '../api/authApi';
import { AUTH } from '../consts';
import { ResetPasswordRequest } from '../types';

const initialValues = {
	email: '',
};

const schema = Yup.object().shape({
	email: Yup.string().label('Email').email().required(),
});

export const ForgottenPasswordForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [resetPassword, { data, isSuccess }] = useResetPasswordMutation();

	const [email, setEmail] = useState('');
	const [emailSubmitted, setEmailSubmitted] = useState(false);

	useEffect(() => {
		if (isSuccess) {
			setEmailSubmitted(true);
		}
	}, [data, dispatch, isSuccess, navigate]);

	if (emailSubmitted) {
		return (
			<>
				<Typography variant='title3' className='text-primaryTextDark mb-8'>
					{AUTH.COMPONENTS.FORGOTTEN_PASSWORD.BODY.PARAGRAPH.A}
				</Typography>
				<Typography variant='body' className='text-primaryTextDark'>
					{`${AUTH.COMPONENTS.FORGOTTEN_PASSWORD.BODY.PARAGRAPH.B} ${email}`}
				</Typography>
				<Typography variant='body' className='text-primaryTextDark'>
					{AUTH.COMPONENTS.FORGOTTEN_PASSWORD.BODY.PARAGRAPH.C}
				</Typography>
				<NavLink to='/' className='flex items-center justify-end text-primaryTextDark mt-8'>
					<Typography variant='link'>
						{AUTH.COMPONENTS.FORGOTTEN_PASSWORD.BODY.RETURN_TO_LOGIN_LINK}
					</Typography>
				</NavLink>
			</>
		);
	} else {
		return (
			<Form
				initialValues={initialValues}
				onSubmit={async (
					values: FormValues,
					actions: FormActions & FormikHelpers<FormValues>
				) => {
					await resetPassword(values as ResetPasswordRequest);
					setEmail((values as ResetPasswordRequest).email);
					actions.setSubmitting(false);
				}}
				schema={schema}
				formStyles='space-y-6'
				buttonText='Reset password'
				buttonStyles='w-full'
			>
				{(formikProps: FormikProps<FormValues>) => {
					const { isSubmitting, isValid, dirty } = formikProps;
					return (
						<>
							<InputField type='email' label='Email' name='email' width='col3' />
							<NavLink to='/' className='flex items-center justify-end'>
								<Typography variant='link' className='text-primaryTextDark'>
									{AUTH.COMPONENTS.FORGOTTEN_PASSWORD.FORM.SIGN_IN_LINK.LABEL}
								</Typography>
							</NavLink>
							<Button
								type='submit'
								variant='primary'
								isLoading={isSubmitting}
								disabled={!isValid || !dirty}
							>
								{AUTH.COMPONENTS.FORGOTTEN_PASSWORD.FORM.SUBMIT_BUTTON.LABEL}
							</Button>
						</>
					);
				}}
			</Form>
		);
	}
};
