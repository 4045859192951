import { Button, Typography } from '@@/components/Elements';
import InformationOutline from 'mdi-material-ui/InformationOutline';

import iconEye from '@/assets/icon_eye.svg';
import { PersonDataObject, DialogContainer } from '@/components';
import { renderHciNameStatus, CellContextExtended } from '@/features/patientLists';
import { useDisclosure } from '@/hooks';
import { trackEvent } from '@/lib/analytics';
import { formatDate } from '@/utils';

export const CancelledPatientsListPreviewPanel = ({ props }: { props: CellContextExtended }) => {
	const { close, open, isOpen } = useDisclosure();
	const { opt_out_date, studies } = props.row.original as PersonDataObject;

	const displayTitle = (title: string) => {
		return (
			<Typography variant='bodySmall' className='text-secondaryTextDark font-semibold'>
				{title}
			</Typography>
		);
	};

	const displayItem = (item: string) => {
		return (
			<Typography variant='body' className='text-primaryTextDark break-words text-base'>
				{item}
			</Typography>
		);
	};

	const thClass = 'text-left pr-2 md:pr-3 xl:pr-6';
	const tdClass = thClass + ' py-1 md:py-2';

	const openButtonClick = (e: React.MouseEvent<HTMLElement>) => {
		open();
		trackEvent('Patient preview clicked');
		// prevent row click handler
		e.stopPropagation();
	};

	return (
		<>
			<div className='flex flex-row'>
				<Button
					variant='icon'
					size='icon'
					className='-ml-2 bg-transparent'
					name='preview_patient'
					onClick={openButtonClick}
					title='Patient details preview'
					data-test-id='patient-preview-button'
				>
					<img alt='Preview icon' src={iconEye} className='h-4' />
				</Button>
			</div>

			<DialogContainer
				close={close}
				isOpen={isOpen}
				className='lg:min-w-[868px] '
				showTopBorder={false}
				dataTestId='patient-preview-panel'
			>
				<div className='flex'>
					<div className='w-[40px] pt-3'>
						<InformationOutline aria-hidden='true' className='text-primaryBrand' />
					</div>
					<div className='flex-1'>
						<div className='grid gap-4 py-4 grid-cols-4 md:grid-cols-5 items-start'>
							<div className='col-span-4'>
								<div className='overflow-auto'>
									<table
										className='table-auto border-separate border-spacing-0 w-full min-w-[500px]'
										data-test-id={'patient-preview-panel-table'}
									>
										<thead>
											<tr>
												<th className={thClass}>
													{displayTitle('Study/Cohort')}
												</th>
												<th className={thClass}>
													{displayTitle('uMed ID')}
												</th>
												<th className={thClass}>{displayTitle('HCI')}</th>
												<th className={thClass}>
													{displayTitle('Opt out date')}
												</th>
											</tr>
										</thead>
										<tbody>
											{studies &&
												studies.map((study, i) => {
													return (
														<tr key={i}>
															<td className={tdClass}>
																{displayItem(study.name)}
															</td>
															<td className={tdClass}>
																{displayItem(
																	study.patient_identifier
																)}
															</td>
															<td className={tdClass}>
																{renderHciNameStatus(
																	study.hci_name,
																	study.hci_active,
																	'text-primaryTextDark break-words text-base'
																)}
															</td>
															<td className={tdClass}>
																{displayItem(
																	`${formatDate(
																		opt_out_date as string
																	)}`
																)}
															</td>
														</tr>
													);
												})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</DialogContainer>
		</>
	);
};
