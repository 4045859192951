import clsx from 'clsx';
import { Field, FieldHookConfig, useField } from 'formik';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

const sizes = {
	lg: 'w-[24px] h-[24px] rounded',
	sm: '',
};

export type CheckboxFieldProps = FieldWrapperPassThroughProps & {
	className?: string;
	labelClassName?: string;
	size?: keyof typeof sizes;
};

export const CheckboxField = (props: CheckboxFieldProps & FieldHookConfig<boolean>) => {
	const { label, className, labelClassName, width, disabled, size = 'sm' } = props;
	const [field, meta] = useField({ ...props, type: 'checkbox' });
	const { error, touched } = meta;

	return (
		<FieldWrapper error={error} touched={touched} width={width}>
			<label className='flex flex-row items-center'>
				<Field
					className={clsx('mr-3 text-success', sizes[size], className)}
					type='checkbox'
					disabled={disabled}
					{...field}
				></Field>
				<span className={`${labelClassName}`}>{label}</span>
			</label>
		</FieldWrapper>
	);
};
