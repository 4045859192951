import { Spinner, Button, Typography } from '@@/components/Elements';
import { FormContainer as Form, FormActions, FormValues } from '@@/components/Form';
import { FormikHelpers, FormikProps } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { SelectField, TextAreaField } from '@/components/Form';
import { IconRadioGroup } from '@/components/Form/IconRadioGroup';
import { CompleteManualScreeningFormValues } from '@/components/Form/types';
import { addNotification } from '@/components/Notifications/notificationsSlice';
import { selectPatientId, selectStudyId } from '@/components/PatientDataTable';
import {
	CheckScreeningRequiredStatus,
	useFetchPatientDetailsQuery,
	util,
} from '@/features/patientDetails';
import { patientListApi } from '@/features/patientLists';
import { reportingApi } from '@/features/reporting';
import { CancelledStatus } from '@/globalConsts';

import {
	useCommitScreeningResultMutation,
	useFetchTrialSiteQuery,
	useSavePatientScreeningResultMutation,
} from '../../api/patientScreeningApi';
import { radioButtons } from '../../consts';

const schema = Yup.object().shape({
	screeningStatus: Yup.string().label('screeningStatus').required(),
	trialSite: Yup.string()
		.nullable(true)
		.label('trialSite')
		.when('screeningStatus', {
			is: '200',
			then: Yup.string().label('trialSite').required(),
		}),
	comments: Yup.string()
		.label('comments')
		.max(120)
		.when('screeningStatus', {
			is: '200',
			then: Yup.string().label('comments').required(),
		}),
});
type CompleteScreeningFormParams = {
	patient_identifier: string;
};

export const CompleteScreeningForm = () => {
	const dispatch = useDispatch();
	const { patient_identifier: patient_id } = useParams<CompleteScreeningFormParams>();
	const patientIdFromState = useSelector(selectPatientId);
	const navigate = useNavigate();
	const studyId = useSelector(selectStudyId);
	const { data, isLoading } = useFetchTrialSiteQuery({ study_id: studyId });
	const { data: patientData, isLoading: patientLoading } = useFetchPatientDetailsQuery(
		patientIdFromState + ''
	);
	const [saveMaualScreeningResult, { isSuccess: savePatientScreeningSuccess }] =
		useSavePatientScreeningResultMutation();
	const [
		commitScreeningResult,
		{ isSuccess: commitScreeningResultSuccess, data: commitPatientData },
	] = useCommitScreeningResultMutation();

	const initialValues: FormValues = {
		screeningStatus:
			patientData && patientData.screening_status ? patientData.screening_status : '',
		trialSite: patientData && patientData.study_site_id ? patientData.study_site_id : '',
		comments: patientData && patientData.trial_site_note ? patientData.trial_site_note : '',
	};
	const handleSave = async (values: FormValues) => {
		await saveMaualScreeningResult({
			patientId: patient_id + '',
			study_site_id:
				(values as CompleteManualScreeningFormValues).trialSite !== ''
					? (values as CompleteManualScreeningFormValues).trialSite
					: null,
			screening_status: (values as CompleteManualScreeningFormValues).screeningStatus,
			...((values as CompleteManualScreeningFormValues).comments !== '' && {
				trial_site_note: (values as CompleteManualScreeningFormValues).comments + '',
			}),
		});
	};
	const screeningStatus = patientData
		? CheckScreeningRequiredStatus(
				patientData.status,
				patientData.status_label,
				patientData.cancelled,
				patientData.cancelled_label
		  )
		: '';
	useEffect(() => {
		if (
			!patientIdFromState ||
			patientIdFromState !== patient_id ||
			!['Screening Required', 'Re-Screening Required'].includes(screeningStatus)
		) {
			navigate('../../', { relative: 'path' });
		}
	}, [
		navigate,
		patient_id,
		commitScreeningResultSuccess,
		patientIdFromState,
		commitPatientData,
		screeningStatus,
	]);
	useEffect(() => {
		if (commitPatientData && commitScreeningResultSuccess) {
			dispatch(
				addNotification({
					type: 'success',
					title: 'Success',
					message: 'You have successfully committed the patient screening result.',
					timeoutLength: 8000,
				})
			);
			if (commitPatientData.cancelled === CancelledStatus.OptedOut) {
				// patient is opted out
				// invalidate the patient list API
				dispatch(patientListApi.util.invalidateTags(['patient_list']));
				dispatch(reportingApi.util.invalidateTags(['cancelled_patient_list']));
				navigate('../../', { relative: 'path' });
			} else {
				dispatch(util.invalidateTags(['patient_details']));
				navigate('../', { relative: 'path' });
			}
		}
	}, [commitPatientData, commitScreeningResultSuccess, dispatch, navigate, patient_id]);
	useEffect(() => {
		if (savePatientScreeningSuccess) {
			dispatch(
				addNotification({
					type: 'success',
					title: 'Success',
					message: 'You have successfully saved the patient screening result.',
					timeoutLength: 8000,
				})
			);
		}
	}, [dispatch, navigate, patient_id, savePatientScreeningSuccess]);
	return (
		<div className='p-6 border-2 border-secondaryBorder rounded col-span-3'>
			<>
				<Typography variant='title1' className='mb-4' dataTestId='CSFtitle'>
					Please enter result of patient manual screening
				</Typography>
				<Typography
					variant='body'
					className='w-1/2 text-secondaryTextDark'
					dataTestId='CSFsubtitle'
				>
					By clicking on the commit button, the information will be forwarded to the trial
					site if it exists in the study
				</Typography>
			</>
			{patientLoading ? (
				<div className='flex flex-row justify-center items-center align-middle h-[640px]'>
					<Spinner variant='light' size='xl' className='' />
				</div>
			) : null}
			<Form
				initialValues={initialValues}
				dataTestId='form-complete-screening'
				onSubmit={async (values: FormValues) => {
					await handleSave(values);
					dispatch(util.invalidateTags(['patient_details']));
					navigate(`/patient-screening/${patient_id}`);
				}}
				schema={schema}
			>
				{(
					formikProps: FormikProps<FormValues> & FormActions & FormikHelpers<FormValues>
				) => {
					const { isSubmitting, isValid, setFieldValue, values, dirty } = formikProps;
					return (
						<>
							<div className='py-10 w-2/3'>
								<IconRadioGroup
									IconRadioButtons={radioButtons}
									selected={radioButtons.find(
										(elm) =>
											elm.value ===
											(values as CompleteManualScreeningFormValues)
												.screeningStatus
									)}
									label='Screening Status'
									className='flex flex-row'
									onChange={(e) => {
										if (e.value !== 200) {
											setFieldValue('trialSite', '');
										}
										setFieldValue('screeningStatus', e.value);
									}}
								></IconRadioGroup>
								{(values as CompleteManualScreeningFormValues).screeningStatus ===
									200 ||
								(values as CompleteManualScreeningFormValues).screeningStatus ==
									'' ? (
									<div className='my-8 w-2/3'>
										{!isLoading && data ? (
											<div className='my-8'>
												<SelectField
													type='text'
													label='Trial site'
													name='trialSite'
													dataTestId='select'
													disabled={
														(
															values as CompleteManualScreeningFormValues
														).screeningStatus !== 200
													}
													width='col6'
													options={data.map(
														(elm: { name: string; id: string }) => {
															return {
																label: elm.name,
																value: elm.id,
															};
														}
													)}
													handleChange={(e) => {
														setFieldValue('trialSite', e.target.value);
													}}
												/>
											</div>
										) : null}
										<TextAreaField
											label='Comments to trial site'
											name='comments'
											disabled={
												(values as CompleteManualScreeningFormValues)
													.screeningStatus !== 200
											}
											placeholder='Describe reason for contact and action that should be taken'
											className='mb-1'
											rows={4}
											width='col2'
											maxLength={120}
											dataTestId='textArea'
										/>
									</div>
								) : null}
							</div>
							<div className='pt-5'>
								<div className='flex flex-row justify-end'>
									<Button
										className='mr-4'
										type='submit'
										isLoading={isSubmitting}
										dataTestId='CSFSaveButton'
										disabled={!isValid || !dirty || isSubmitting}
									>
										Save
									</Button>
									<Button
										type='button'
										disabled={
											!isValid ||
											isSubmitting ||
											Object.values(values).every((elm) => elm === '')
										}
										dataTestId='CSFCommitButton'
										onClick={async () => {
											await handleSave(values);
											await commitScreeningResult(patient_id + '');
										}}
									>
										Commit
									</Button>
								</div>
							</div>
						</>
					);
				}}
			</Form>
		</div>
	);
};
